<template>
  <BCard title="중재 정보">
    <BTable
      :fields="fields"
      :items="state.claimList"
      show-empty
      small
    >
      <template #cell(campaign_idx)="data">
        <ComptIdxColumn :campaignIdx="data.value" />
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
  },
  setup() {
    const fields = [
      'idx',
      'campaign_idx',
      'title',
      'step',
      'reason',
      'state',
      'created_at',
    ]

    return {
      state,
      fields,
    }
  },
})
</script>
