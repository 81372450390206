<template>
  <TagbyPrimaryModal
    :visible="state.addressHandle.isVisibleSetAddressStateModal"
    title="주소상태 수정"
    :content="`주소상태를 ${state.addressHandle.stateToChange}로 수정하시겠습니까?`"
    @ok="setAddressState"
    @hidden="turnOffSetAddressStateModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useAddress from '../../useAddress'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setAddressState,
      turnOffSetAddressStateModal,
    } = useAddress()

    return {
      state,
      setAddressState,
      turnOffSetAddressStateModal,
    }
  },
})
</script>
