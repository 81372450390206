import {
  computed,
  reactive,
} from '@vue/composition-api'

import state from './state'

const isCreatingMode = computed(() => !state.idx)
const campaignItems = computed(() => state.campaignList.filter(c => {
  if (state.campaignFilter.applyState.length > 0) {
    return state.campaignFilter.applyState.includes(c.apply_state)
  }
  return true
}).filter(c => {
  if (state.campaignFilter.isProgressing) {
    return c.is_progressing
  }
  return true
}).filter(c => {
  if (state.campaignFilter.in30days) {
    return c.in30days
  }
  return true
}).filter(c => {
  if (state.campaignFilter.title) {
    return c.title.includes(state.campaignFilter.title)
  }
  return true
}))
const campaignFrom = computed(() => {
  const { perPage, currentPage } = state.campaignFilter
  return (currentPage - 1) * perPage + 1
})
const campaignOf = computed(() => campaignItems.value.length)
const campaignTo = computed(() => {
  const { perPage } = state.campaignFilter
  const endRow = campaignFrom.value + perPage
  if (endRow < campaignOf.value) {
    return endRow
  }
  return campaignOf.value
})
const snsItems = computed(() => state.snsList.filter(s => {
  if (state.snsFilter.excludeInactive) {
    return s.state !== 'INACTIVE'
  }
  return true
}))
const claimCount = computed(() => state.claimList.filter(c => c.state !== 'RESOLVED').length ?? 0)

const activeMyshopIdx = computed(() => state.myshopList.find(m => m.state === 'ACTIVE')?.idx)

const pointTax = computed(() => {
  const {
    amount,
    taxPercent,
  } = state.managePointSidebar
  const tax = (amount * taxPercent) / 100
  return parseInt(tax, 10)
})
const applyPoint = computed(() => state.managePointSidebar.amount - pointTax.value)
const isValidPointAmount = computed(() => state.managePointSidebar.amount >= 0)

const getLevel = score => {
  if (score <= 4) {
    return 1
  }
  if (score <= 8) {
    return 2
  }
  if (score <= 12) {
    return 3
  }
  if (score <= 16) {
    return 4
  }
  return 5
}
const timeLevel = computed(() => getLevel(state.updateScoreSidebar.timeScore))
const guideLevel = computed(() => getLevel(state.updateScoreSidebar.guideScore))
const contactLevel = computed(() => getLevel(state.updateScoreSidebar.contactScore))

const totalScore = computed(() => state.updateScoreSidebar.timeScore + state.updateScoreSidebar.guideScore + state.updateScoreSidebar.contactScore)
const totalLevel = computed(() => {
  if (totalScore.value <= 12) {
    return 1
  }
  if (totalScore.value <= 24) {
    return 2
  }
  if (totalScore.value <= 36) {
    return 3
  }
  if (totalScore.value <= 48) {
    return 4
  }
  return 5
})

export default reactive({
  isCreatingMode,
  campaignMeta: {
    items: campaignItems,
    from: campaignFrom,
    to: campaignTo,
    of: campaignOf,
  },
  snsItems,
  claimCount,
  activeMyshopIdx,
  managePointSidebar: {
    applyPoint,
    pointTax,
    isValidPointAmount,
  },
  updateScoreSidebar: {
    timeLevel,
    guideLevel,
    contactLevel,
    totalScore,
    totalLevel,
  },
})
