<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isValid="true"
    variant="outline-secondary"
    text="SNS 추가"
    @click="turnOnAddSnsSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useSns from '../../useSns'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      turnOnAddSnsSidebar,
    } = useSns()

    return {
      state,
      getters,
      turnOnAddSnsSidebar,
    }
  },
})
</script>
