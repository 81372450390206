<template>
  <BCard title="캠페인 정보">
    <CampaignFilter />
    <CampaignTable />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import CampaignFilter from './CampaignFilter.vue'
import CampaignTable from './CampaignTable.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    CampaignFilter,
    CampaignTable,
  },
  setup() {

  },
})
</script>
