<template>
  <div>
    <MyshopDetail v-if="getters.activeMyshopIdx != null" />
    <MyshopList v-else-if="state.myshopList.length > 0" />
    <BRow v-else>
      <BCol class="text-muted font-italic d-flex justify-content-center">
        Empty list
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import MyshopDetail from './MyshopDetail.vue'
import MyshopList from './MyshopList.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BRow,
    BCol,
    MyshopDetail,
    MyshopList,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
