<template>
  <TagbySidebarWithButton
    :visible="state.managePointSidebar.isVisible"
    :isSaving="state.managePointSidebar.isSaving"
    :isValid="getters.managePointSidebar.isValidPointAmount"
    title="포인트 관리"
    width="30%"
    @ok="managePoint"
    @hidden="turnOffManagePointSidebar"
    @cancel="turnOffManagePointSidebar"
  >
    <TagbyInputRow label="STATE">
      <TagbySelect
        v-model="state.managePointSidebar.state"
        :options="stateOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow label="AMOUNT">
      <BInputGroup :prepend="['ADDITION', 'ADDITION_EXPECTED'].includes(state.managePointSidebar.state) ? null : '-'">
        <BFormInput
          v-model="state.managePointSidebar.amount"
          number
          type="number"
        />
      </BInputGroup>
    </TagbyInputRow>

    <TagbyInputRow
      v-if="['ADDITION', 'ADDITION_EXPECTED'].includes(state.managePointSidebar.state)"
      label="TAX PERCENT"
    >
      <BInputGroup append="%">
        <BFormInput
          v-model="state.managePointSidebar.taxPercent"
          number
          type="number"
          max="100"
          min="0"
        />
      </BInputGroup>
    </TagbyInputRow>

    <TagbyInputRow
      v-if="['ADDITION', 'ADDITION_EXPECTED'].includes(state.managePointSidebar.state)"
      label="APPLY POINT"
    >
      <BFormInput
        :value="getters.managePointSidebar.applyPoint"
        readonly
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-if="['ADDITION', 'ADDITION_EXPECTED'].includes(state.managePointSidebar.state)"
      label="TAX"
    >
      <BFormInput
        :value="getters.managePointSidebar.pointTax"
        readonly
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-if="['ADDITION'].includes(state.managePointSidebar.state)"
      label="EXPIRES AT"
    >
      <TagbyDatetimeInputV3
        v-model="state.managePointSidebar.expiresAt"
        :config="{ dateFormat: 'Y-m-d' }"
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-else-if="['ADDITION_EXPECTED'].includes(state.managePointSidebar.state)"
      label="ADDITION EXPECTED DATE"
    >
      <TagbyDatetimeInputV3
        v-model="state.managePointSidebar.additionExpectedDate"
        :config="{ dateFormat: 'Y-m-d' }"
      />
    </TagbyInputRow>

    <TagbyInputRow
      label="REASON"
    >
      <BFormTextarea
        v-model="state.managePointSidebar.reason"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import getters from '../../getters'
import usePoint from '../../usePoint'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    TagbySelect,
    TagbyDatetimeInputV3,
  },
  setup() {
    const {
      turnOffManagePointSidebar,
      managePoint,
    } = usePoint()

    const stateOptions = [
      'ADDITION',
      'ADDITION_EXPECTED',
      'CANCEL',
    ]

    return {
      state,
      getters,
      stateOptions,
      turnOffManagePointSidebar,
      managePoint,
    }
  },
})
</script>
