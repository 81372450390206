<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isValid="true"
    variant="outline-secondary"
    text="포인트 관리"
    @click="turnOnManagePointSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import usePoint from '../../usePoint'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      turnOnManagePointSidebar,
    } = usePoint()

    return {
      state,
      getters,
      turnOnManagePointSidebar,
    }
  },
})
</script>
