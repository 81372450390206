<template>
  <TagbySidebarWithButton
    title="평점 수정"
    :visible="state.updateScoreSidebar.isVisible"
    :isSaving="state.updateScoreSidebar.isSaving"
    :isValid="true"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
    @ok="updateScore"
  >
    <TagbyInputRow label="total">
      {{ getters.updateScoreSidebar.totalScore }} / 60 (Level {{ getters.updateScoreSidebar.totalLevel }})
      <BFormInput
        :value="getters.updateScoreSidebar.totalScore"
        number
        max="60"
        min="0"
        type="range"
        disabled
      />
    </TagbyInputRow>

    <TagbyInputRow label="time">
      {{ state.updateScoreSidebar.timeScore }} / 20 (Level {{ getters.updateScoreSidebar.timeLevel }})
      <BFormInput
        v-model="state.updateScoreSidebar.timeScore"
        number
        max="20"
        min="0"
        type="range"
      />
    </TagbyInputRow>

    <TagbyInputRow label="guide">
      {{ state.updateScoreSidebar.guideScore }} / 20 (Level {{ getters.updateScoreSidebar.guideLevel }})
      <BFormInput
        v-model="state.updateScoreSidebar.guideScore"
        number
        max="20"
        min="0"
        type="range"
      />
    </TagbyInputRow>

    <TagbyInputRow label="contact">
      {{ state.updateScoreSidebar.contactScore }} / 20 (Level {{ getters.updateScoreSidebar.contactLevel }})
      <BFormInput
        v-model="state.updateScoreSidebar.contactScore"
        number
        max="20"
        min="0"
        type="range"
      />
    </TagbyInputRow>

    <TagbyInputRow label="comment">
      <BFormTextarea
        v-model="state.updateScoreSidebar.comment"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import getters from '../../getters'
import useScore from '../../useScore'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      updateScore,
    } = useScore()

    return {
      state,
      getters,
      turnOffSidebar,
      updateScore,
    }
  },
})
</script>
