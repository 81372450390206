<template>
  <BCard no-body>
    <BTable
      :items="getters.campaignMeta.items"
      :fields="fields"
      show-empty
      responsive
      small
      :per-page="state.campaignFilter.perPage"
      :current-page="state.campaignFilter.currentPage"
    >
      <template #cell(idx)="data">
        <ComptIdxColumn :campaignIdx="data.value" />
      </template>

      <template #cell(apply_date)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
    <BRow>
      <BCol
        cols="6"
        class="d-flex align-items-center justify-content-star pl-2"
      >
        <span class="text-muted">Showing {{ getters.campaignMeta.from }} to {{ getters.campaignMeta.to }} of {{ getters.campaignMeta.of }} entries</span>
      </BCol>
      <BCol
        :cols="6"
        class="d-flex align-items-center justify-content-end"
      >
        <BPagination
          v-model="state.campaignFilter.currentPage"
          :total-rows="getters.campaignMeta.of"
          :per-page="state.campaignFilter.perPage"
          align="center"
          size="sm"
          first-number
          last-number
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
} from 'bootstrap-vue'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    BRow,
    BCol,
    BPagination,
  },
  setup() {
    const fields = [
      'idx',
      'title',
      'brand',
      'step',
      'state',
      'apply_state',
      'apply_date',
    ]
    return {
      state,
      getters,
      fields,
    }
  },
})
</script>
