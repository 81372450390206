<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.myshopList"
      show-empty
      small
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            text="Set Active"
            @click="turnOnSetMyshopStateModal(data.item, 'ACTIVE')"
          />
          <TagbyTableActionItem
            text="Update"
            @click="turnOnUpdateMyshopSidebar(data.item)"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import state from '../../state'
import useMyshop from '../../useMyshop'

export default defineComponent({
  components: {
    BCard,
    BTable,
    TagbyDatetimeColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const fields = [
      'idx',
      'name',
      'url_path',
      'state',
      'created_at',
      'actions',
    ]
    const {
      turnOnSetMyshopStateModal,
      turnOnUpdateMyshopSidebar,
    } = useMyshop()

    return {
      state,
      fields,
      turnOnSetMyshopStateModal,
      turnOnUpdateMyshopSidebar,
    }
  },
})
</script>
