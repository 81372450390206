import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getSnsAuthList,
    getTimelineList,
  } = useFetch()

  const turnOnSetSnsAuthStateModal = (snsAuth, stateToChange) => {
    state.snsAuthHandle.targetSnsAuth = snsAuth
    state.snsAuthHandle.stateToChange = stateToChange
    state.snsAuthHandle.isVisibleSetSnsAuthStateModal = true
  }
  const turnOffSetSnsAuthStateModal = () => {
    state.snsAuthHandle.isVisibleSetSnsAuthStateModal = false
  }

  const setSnsAuthState = () => api.setSnsAuthState({
    inf_idx: state.idx,
    sns_auth_idx: state.snsAuthHandle.targetSnsAuth.idx,
    state: state.snsAuthHandle.stateToChange,
  }).then(() => {
    makeToast('primary', 'Successfully changed sns auth state')
    getSnsAuthList()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', 'Failed to change sns auth state')
    turnOnErrorReasonModal(err)
  })

  return {
    turnOnSetSnsAuthStateModal,
    turnOffSetSnsAuthStateModal,
    setSnsAuthState,
  }
}
