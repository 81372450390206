<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.agreeLogList"
      show-empty
      small
      caption="LOG LIST"
      caption-top
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    TagbyDatetimeColumn,
  },
  setup() {
    const fields = [
      'idx',
      'channel',
      'purpose',
      'is_agree',
      'created_at',
    ]
    return {
      state,
      fields,
    }
  },
})
</script>
