<template>
  <div>
    <TokenCard v-if="state.adminPermission === 'ADMIN'" />
    <DeviceTable />
    <SnsAuthTable />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import state from '../../state'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import TokenCard from './TokenCard.vue'
import DeviceTable from './DeviceTable.vue'
import SnsAuthTable from './SnsAuthTable.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyCopyIcon,
    TokenCard,
    DeviceTable,
    SnsAuthTable,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
