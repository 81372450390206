<template>
  <BCard>
    <BRow>
      <BCol>
        <BFormGroup label="ISSUED TOKEN">
          {{ state.token }}
          <TagbyCopyIcon :value="state.token" />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import state from '../../state'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    TagbyCopyIcon,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
