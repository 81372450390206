<template>
  <BCard>
    <BRow>
      <BCol>
        <BCardHeader class="tg-card-header">
          <span>
            <feather-icon
              icon="VideoIcon"
              size="24"
            />
            Tiktok
          </span>
          <BDropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body"
              />
            </template>
            <BDropdownItem
              href="#"
              :disabled="sns.state === 'INACTIVE'"
              @click="turnOnSetSnsStateModal(sns, 'INACTIVE')"
            >
              INACTIVE 처리
            </BDropdownItem>
            <BDropdownItem
              href="#"
              :disabled="sns.state === 'ACTIVE'"
              @click="turnOnSetSnsStateModal(sns, 'ACTIVE')"
            >
              ACTIVE 처리
            </BDropdownItem>
            <BDropdownItem
              href="#"
              :disabled="sns.state === 'RELOGIN'"
              @click="turnOnSetSnsStateModal(sns, 'RELOGIN')"
            >
              RELOGIN 처리
            </BDropdownItem>
          </BDropdown>
        </BCardHeader>
        <BCardText class="text-muted font-small-2">
          <span class="font-weight-bolder">last updated at </span>
          <span>{{ sns.updated_at }}</span>
        </BCardText>
      </BCol>
    </BRow>

    <hr class="mb-2">

    <BRow
      class="d-flex flex-nowrap text-break"
      no-gutters
    >
      <div class="mr-1">
        <BAvatar
          size="60"
          :src="sns.profile_img_url"
          target="_blank"
          :href="sns.landing_url"
        />
      </div>
      <div>
        <BBadge
          href="#"
          :variant="getStateVariant(sns.state)"
        >
          <span>{{ sns.state }}</span>
        </BBadge>
        <div>
          <span>{{ sns.idx }}</span>
          <TagbyCopyIcon :value="sns.idx" />
        </div>
        <div>
          <span
            class="d-inline-block text-truncate"
            style="max-width: 100px;"
          >{{ sns.uid }}</span>
          <TagbyCopyIcon :value="sns.uid" />
        </div>
        <div>
          <span>{{ sns.username }}</span>
          <TagbyCopyIcon :value="sns.username" />
        </div>
      </div>
    </BRow>

    <hr class="mb-2">

    <BRow>
      <BCol class="d-flex justify-content-between">
        <div>
          <h6 class="text-muted font-weight-bolder">
            Follower
          </h6>
          <h5>
            {{ sns.follower_count }}
          </h5>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Follow
          </h6>
          <h5>
            {{ sns.follow_count }}
          </h5>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Post
          </h6>
          <h5>
            {{ sns.post_count }}
          </h5>
        </div>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardText,
  BAvatar,
} from 'bootstrap-vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Spinner from '@/views/example/components/spinner/Spinner.vue'
import useSns from '../../useSns'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardText,
    BAvatar,
    TagbyCopyIcon,
    FeatherIcon,
    Spinner,
  },
  props: {
    sns: {
      type: Object,
    },
  },
  setup() {
    const getStateVariant = state => {
      if (state === 'ACTIVE') {
        return 'primary'
      }
      if (state === 'RELOGIN') {
        return 'info'
      }
      if (state === 'WAIT') {
        return 'warning'
      }
      if (state === 'INACTIVE') {
        return 'secondary'
      }
    }

    const {
      turnOnSetSnsStateModal,
    } = useSns()

    return {
      getStateVariant,
      turnOnSetSnsStateModal,
    }
  },
})
</script>

<style scoped>
.card-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: none;
  padding: 0px;
  background-color: transparent;
}
</style>
