<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.scoreLogList"
      show-empty
      small
      caption="LOG LIST"
      caption-top
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
  },
  setup() {
    const fields = [
      'idx',
      'info',
      'purpose',
      'comment',
      'before',
      'score',
      'created_at',
    ]

    return {
      state,
      fields,
    }
  },
})
</script>
