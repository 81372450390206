import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const getParams = () => {
    const params = {}
    params.idx = state.idx

    if (state.snapshot.name !== state.name) {
      params.name = state.name
    }

    if (state.snapshot.phone !== state.phone) {
      params.phone = state.phone
    }

    if (state.snapshot.gender !== state.gender) {
      params.gender = state.gender
    }

    if (state.snapshot.job !== state.job) {
      params.job = state.job
    }

    if (state.snapshot.birth !== state.birth) {
      params.birth = state.birth
    }

    if (state.snapshot.state !== state.state) {
      params.state = state.state
    }

    if (state.snapshot.restricted_date !== state.restrictedDate) {
      params.restricted_date = state.restrictedDate
    }

    return params
  }

  const updateInf = () => {
    state.infHandle.isSavingInf = true
    const params = getParams()
    return api.updateInf(params).then(() => {
      makeToast('primary', 'Successfully updated inf info')
      getDetail()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', 'Failed to update inf info')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.infHandle.isSavingInf = false
    })
  }

  const turnOnBanInfModal = () => {
    state.banInfModal.isVisible = true
  }
  const turnOffBanInfModal = () => {
    state.banInfModal.isVisible = false
  }
  const banInf = () => api.banInf({
    inf_idx: state.idx,
  }).then(() => {
    makeToast('primary', 'Successfully banned inf')
    getDetail()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', 'Failed to ban inf')
    turnOnErrorReasonModal(err)
  })

  const turnOnWithdrawInfSidebar = () => {
    state.withdrawInfSidebar.isVisible = true
  }
  const turnOffWithdrawInfSidebar = () => {
    state.withdrawInfSidebar.isVisible = false
  }
  const getWithdrawReason = () => {
    if (state.withdrawInfSidebar.selectedReason !== '기타') {
      return state.withdrawInfSidebar.selectedReason
    }
    return state.withdrawInfSidebar.writtenReason
  }
  const withdrawInf = () => {
    state.withdrawInfSidebar.isSaving = true
    return api.withdrawInf({
      inf_idx: state.idx,
      reason: getWithdrawReason(),
    }).then(() => {
      makeToast('primary', 'Successfully withdrawn inf')
      getDetail()
      getTimelineList()
      turnOffWithdrawInfSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to withdraw inf')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.withdrawInfSidebar.isSaving = false
    })
  }

  return {
    updateInf,

    turnOnBanInfModal,
    turnOffBanInfModal,
    banInf,

    turnOnWithdrawInfSidebar,
    turnOffWithdrawInfSidebar,
    withdrawInf,
  }
}
