<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.deviceList"
      show-empty
      small
      responsive
      caption="DEVICE LIST"
      caption-top
    >
      <template #cell(device_token)="data">
        <div class="d-flex">
          <div
            class="text-truncate"
            style="width: 200px"
          >
            {{ data.value }}
          </div>
          <TagbyCopyIcon :value="data.value" />
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    TagbyCopyIcon,
    TagbyDatetimeColumn,
  },
  setup() {
    const fields = [
      'idx',
      'os',
      'version',
      'device_token',
      'state',
      'created_at',
    ]
    return {
      state,
      fields,
    }
  },
})
</script>
