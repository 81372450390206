<template>
  <TagbyPrimaryModal
    :visible="state.snsHandle.isVisibleSetSnsStateModal"
    title="SNS상태 수정"
    :content="`SNS상태를 ${state.snsHandle.stateToChange}로 수정하시겠습니까?`"
    @ok="setSnsState"
    @hidden="turnOffSetSnsStateModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useSns from '../../useSns'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setSnsState,
      turnOffSetSnsStateModal,
    } = useSns()

    return {
      state,
      setSnsState,
      turnOffSetSnsStateModal,
    }
  },
})
</script>
