<template>
  <TagbyDangerModal
    :visible="state.banInfModal.isVisible"
    title="인플루언서 블랙"
    content="해당 인플루언서를 블랙처리 하시겠습니까?"
    @ok="banInf"
    @hidden="turnOffBanInfModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import state from '../../state'
import useInf from '../../useInf'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      turnOffBanInfModal,
      banInf,
    } = useInf()

    return {
      state,
      turnOffBanInfModal,
      banInf,
    }
  },
})
</script>
