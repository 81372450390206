<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isValid="true"
    variant="outline-secondary"
    text="메모"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useMemo from '../../useMemo'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useMemo()

    return {
      state,
      getters,
      turnOnSidebar,
    }
  },
})
</script>
