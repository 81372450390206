<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(default)>
          <InfoCard />
          <SummaryCard />
          <CampaignList />
          <ClaimList />
          <CautionList />
          <SnsList />
        </template>

        <!-- <template #tab(campaign)>
        </template> -->

        <!-- <template #tab(sns)>
        </template> -->

        <template #tab(myshop)>
          <MyshopCard />
        </template>

        <template #tab(auth)>
          <AuthCard />
        </template>

        <template #tab(link)>
          <LinkCard />
        </template>

        <template #tab(ssn)>
          <SsnList />
        </template>

        <template #tab(agree)>
          <AgreeList />
        </template>

        <template #tab(bank)>
          <BankCard />
        </template>

        <!-- <template #tab(claim)>
        </template> -->

        <!-- <template #tab(caution)>
        </template> -->

        <template #tab(score)>
          <ScoreList />
        </template>

        <template #tab(address)>
          <AddressList />
        </template>

      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        v-if="!getters.isCreatingMode"
        :timelineList="state.timelineList"
      />
    </template>

    <template #hidden>
      <ErrorReasonModal />
      <SetSnsStateModal />
      <IssueTicketModal />
      <SetMyshopStateModal />
      <UpdateMyshopSidebar />
      <SetSnsAuthStateModal />
      <SetSsnStateModal />
      <SetCautionStateModal />
      <SetAddressStateModal />
      <AddAddressSidebar />
      <ManagePointSidebar />
      <AddCautionSidebar />
      <CreateMemoSidebar />
      <UpdateScoreSidebar />
      <BanInfModal />
      <WithdrawInfSidebar />
      <AddSnsSidebar />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import { getRouterIdx } from "@/utils/router"
import TagbyTabs from "@/components/TagbyTabs.vue"
import TagbyDetailLayout from "@/components/TagbyDetailLayout.vue"
import TagbyTimelinesV3 from "@/components/TagbyTimelinesV3.vue"
import ActionCard from "./components/ActionCard/Main.vue"
import useFetch from "./useFetch"
import ErrorReasonModal from "./components/Modals/ErrorReasonModal.vue"
import state from "./state"
import getters from "./getters"
import useState from "./useState"
import InfoCard from "./components/InfoCard/Main.vue"
import SummaryCard from "./components/SummaryCard/Main.vue"
import CampaignList from "./components/CampaignList/Main.vue"
import SnsList from "./components/SnsList/Main.vue"
import MyshopCard from "./components/MyshopCard/Main.vue"
import AuthCard from "./components/AuthCard/Main.vue"
import LinkCard from "./components/LinkCard/Main.vue"
import SsnList from "./components/SsnList/Main.vue"
import AgreeList from "./components/AgreeList/Main.vue"
import ClaimList from "./components/ClaimList/Main.vue"
import CautionList from "./components/CautionList/Main.vue"
import ScoreList from "./components/ScoreList/Main.vue"
import AddressList from "./components/AddressList/Main.vue"
import SetSnsStateModal from "./components/Modals/SetSnsStateModal.vue"
import IssueTicketModal from "./components/Modals/IssueTicketModal.vue"
import SetMyshopStateModal from "./components/Modals/SetMyshopStateModal.vue"
import UpdateMyshopSidebar from "./components/Sidebars/UpdateMyshopSidebar.vue"
import SetSnsAuthStateModal from "./components/Modals/SetSnsAuthStateModal.vue"
import SetSsnStateModal from "./components/Modals/SetSsnStateModal.vue"
import SetCautionStateModal from "./components/Modals/SetCautionStateModal.vue"
import SetAddressStateModal from "./components/Modals/SetAddressStateModal.vue"
import AddAddressSidebar from "./components/Sidebars/AddAddressSidebar.vue"
import ManagePointSidebar from "./components/Sidebars/ManagePointSidebar.vue"
import AddCautionSidebar from "./components/Sidebars/AddCautionSidebar.vue"
import CreateMemoSidebar from "./components/Sidebars/CreateMemoSidebar.vue"
import UpdateScoreSidebar from "./components/Sidebars/UpdateScoreSidebar.vue"
import BanInfModal from "./components/Modals/BanInfModal.vue"
import WithdrawInfSidebar from "./components/Sidebars/WithdrawInfSidebar.vue"
import AddSnsSidebar from "./components/Sidebars/AddSnsSidebar.vue"
import BankCard from "./components/BankCard"

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    TagbyTimelinesV3,
    ErrorReasonModal,
    InfoCard,
    SummaryCard,
    CampaignList,
    SnsList,
    MyshopCard,
    AuthCard,
    LinkCard,
    SsnList,
    AgreeList,
    ClaimList,
    CautionList,
    ScoreList,
    AddressList,
    SetSnsStateModal,
    IssueTicketModal,
    SetMyshopStateModal,
    UpdateMyshopSidebar,
    SetSnsAuthStateModal,
    SetSsnStateModal,
    SetCautionStateModal,
    SetAddressStateModal,
    AddAddressSidebar,
    ManagePointSidebar,
    AddCautionSidebar,
    CreateMemoSidebar,
    UpdateScoreSidebar,
    BanInfModal,
    WithdrawInfSidebar,
    AddSnsSidebar,
    BankCard,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    state.idx = getRouterIdx()

    const {
      getAdminMe,
      getDetail,
      getCampaignList,
      getSnsList,
      getMyshopPipeline,
      getInfToken,
      getDeviceList,
      getSnsAuthList,
      getTagbyLink,
      getSsnList,
      getAgreeList,
      getAgreeLogList,
      getClaimList,
      getCautionList,
      getScoreList,
      getScoreLostList,
      getAddressList,
      getTimelineList,
      getBank,
    } = useFetch()

    if (!getters.isCreatingMode) {
      getAdminMe().then(() => {
        if (state.adminPermission === "ADMIN") {
          getInfToken()
        }
      })
      getDetail()
      getCampaignList()
      getSnsList()
      getMyshopPipeline()
      getDeviceList()
      getSnsAuthList()
      getTagbyLink()
      getSsnList()
      getAgreeList()
      getAgreeLogList()
      getClaimList()
      getCautionList()
      getScoreList()
      getScoreLostList()
      getAddressList()
      getTimelineList()
      getBank()
    }

    const tabList = [
      { key: "default", title: "자주 확인하는 정보" },
      // { key: 'campaign', title: '캠페인', visible: !getters.isCreatingMode },
      // { key: 'sns', title: 'SNS', visible: !getters.isCreatingMode },
      { key: "myshop", title: "마이스토어", visible: !getters.isCreatingMode },
      { key: "auth", title: "로그인관련", visible: !getters.isCreatingMode },
      { key: "link", title: "태그바이링크", visible: !getters.isCreatingMode },
      { key: "ssn", title: "실명인증", visible: !getters.isCreatingMode },
      { key: "bank", title: "계좌", visible: !getters.isCreatingMode },
      { key: "agree", title: "알림동의", visible: !getters.isCreatingMode },
      // { key: 'claim', title: '중재', visible: !getters.isCreatingMode },
      // { key: 'caution', title: '경고', visible: !getters.isCreatingMode },
      { key: "score", title: "평점", visible: !getters.isCreatingMode },
      { key: "address", title: "주소", visible: !getters.isCreatingMode },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
