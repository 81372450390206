<template>
  <div>
    <AgreeTable />
    <LogTable />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import AgreeTable from './AgreeTable.vue'
import LogTable from './LogTable.vue'

export default defineComponent({
  components: {
    AgreeTable,
    LogTable,
  },
  setup() {

  },
})
</script>
