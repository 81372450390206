<template>
  <TagbyPrimaryModal
    :visible="state.ssnHandle.isVisibleSetSsnStateModal"
    title="SSN상태 수정"
    :content="`SSN상태를 ${state.ssnHandle.stateToChange}로 수정하시겠습니까?`"
    @ok="setSsnState"
    @hidden="turnOffSetSsnStateModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useSsn from '../../useSsn'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setSsnState,
      turnOffSetSsnStateModal,
    } = useSsn()

    return {
      state,
      setSsnState,
      turnOffSetSsnStateModal,
    }
  },
})
</script>
