import axiosFrame from "@/utils/axiosFrame"

export default {
  getAdminMe: axiosFrame("get", "/manage/admin/me/", null),
  getDetail: axiosFrame("get", "/manage/inf-re/detail/", null),
  getCampaignList: axiosFrame("get", "/manage/inf-re/campaign-list/", null),
  getSnsList: axiosFrame("get", "/manage/inf-re/sns-list/", null),
  getMyshopList: axiosFrame("get", "/manage/inf-re/myshop-list/", null),
  getMyshop: axiosFrame("get", "/manage/inf-re/myshop/", null),
  getInfToken: axiosFrame("get", "/manage/inf-re/token/", null),
  getDeviceList: axiosFrame("get", "/manage/inf-re/device-list/", null),
  getSnsAuthList: axiosFrame("get", "/manage/inf-re/sns-auth-list/", null),
  getTagbyLink: axiosFrame("get", "/manage/inf-re/tagby-link/", null),
  getSsnList: axiosFrame("get", "/manage/inf-re/ssn-list/", null),
  getAgreeList: axiosFrame("get", "/manage/inf-re/agree-list/", null),
  getAgreeLogList: axiosFrame("get", "/manage/inf-re/agree-log-list/", null),
  getClaimList: axiosFrame("get", "/manage/inf-re/claim-list/", null),
  getCautionList: axiosFrame("get", "/manage/inf-re/caution-list/", null),
  getScoreList: axiosFrame("get", "/manage/inf-re/score-list/", null),
  getScoreLostList: axiosFrame("get", "/manage/inf-re/score-log-list/", null),
  getAddressList: axiosFrame("get", "/manage/inf-re/address-list/", null),
  getTimelineList: axiosFrame("get", "/manage/inf-re/timeline-list/", null),
  setSnsState: axiosFrame("post", "/manage/inf-re/set-sns-state/", null),
  issueTicket: axiosFrame("post", "/manage/inf-re/issue-ticket/", null),
  setMyshopState: axiosFrame("post", "/manage/inf-re/set-myshop-state/", null),
  updateMyshop: axiosFrame("post", "/manage/inf-re/update-myshop/", null),
  setSnsAuthState: axiosFrame("post", "/manage/inf-re/set-sns-auth-state/", null),
  setSsnState: axiosFrame("post", "/manage/inf-re/set-ssn-state/", null),
  setCautionState: axiosFrame("post", "/manage/inf-re/set-caution-state/", null),
  setAddressActive: axiosFrame("post", "/manage/inf-re/set-address-active/", null),
  setAddressInactive: axiosFrame("post", "/manage/inf-re/set-address-inactive/", null),
  setAddressMain: axiosFrame("post", "/manage/inf-re/set-address-main/", null),
  updateInf: axiosFrame("post", "/manage/inf-re/update-inf/", null),
  addAddress: axiosFrame("post", "/manage/inf-re/add-address/", null),
  managePoint: axiosFrame("post", "/manage/inf-re/manage-point/", null),
  addCaution: axiosFrame("post", "/manage/inf-re/add-caution/", null),
  createMemo: axiosFrame("post", "/manage/memo/create-memo/", null),
  updateScore: axiosFrame("post", "/manage/inf-re/update-score/", null),
  banInf: axiosFrame("post", "/manage/inf-re/ban-inf/", null),
  withdrawInf: axiosFrame("post", "/manage/inf-re/withdraw-inf/", null),
  addSns: axiosFrame("post", "/manage/inf-re/add-sns/", null),
  getBank: axiosFrame("get", "/manage/inf-re/bank/", null),
}
