<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.scoreList"
      show-empty
      small
      caption="SCORE LIST"
      caption-top
    >

      <template #cell(last_recovery_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(updated_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
  },
  setup() {
    const fields = [
      'idx',
      'type',
      'score',
      'last_recovery_at',
      'updated_at',
    ]

    return {
      state,
      fields,
    }
  },
})
</script>
