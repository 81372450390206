<template>
  <BCard title="경고 정보">
    <BTable
      :fields="fields"
      :items="state.cautionList"
      show-empty
      small
    >
      <template #cell(info)="data">
        <div v-if="data.item.info_table === 'campaign_info'">
          <div>
            CAMPAIGN
          </div>
          <div>
            <ComptIdxColumn :campaignIdx="data.item.info_idx" />
          </div>
        </div>
        <div v-else-if="data.item.info_table === 'product_info'">
          <div>
            PRODUCT
          </div>
          <div>
            {{ data.item.info_idx }}
          </div>
        </div>
        <div v-else>
          ETC
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            :disabled="data.item.state === 'ADDITION'"
            text="Set Addition"
            @click="turnOnSetCautionStateModal(data.item, 'ADDITION')"
          />
          <TagbyTableActionItem
            :disabled="data.item.state === 'CANCELED'"
            text="Set Canceled"
            @click="turnOnSetCautionStateModal(data.item, 'CANCELED')"
          />
          <TagbyTableActionItem
            :disabled="data.item.state === 'INACTIVE'"
            text="Set Inactive"
            @click="turnOnSetCautionStateModal(data.item, 'INACTIVE')"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import useCaution from '../../useCaution'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const fields = [
      'idx',
      'info',
      'reason',
      'state',
      'is_read',
      'created_at',
      'actions',
    ]
    const {
      turnOnSetCautionStateModal,
    } = useCaution()

    return {
      state,
      fields,
      turnOnSetCautionStateModal,
    }
  },
})
</script>
