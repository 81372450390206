<template>
  <div>
    <SnsFilter />
    <BRow v-if="state.snsList.length > 0">
      <BCol
        v-for="sns in getters.snsItems"
        :key="sns.idx"
        cols="12"
        sm="6"
        md="4"
      >
        <InstaCard
          v-if="sns.service.includes('INSTA')"
          :sns="sns"
        />
        <NaverCard
          v-else-if="sns.service === 'NAVER_BLOG'"
          :sns="sns"
        />
        <YoutubeCard
          v-else-if="sns.service === 'YOUTUBE'"
          :sns="sns"
        />
        <TiktokCard
          v-else-if="sns.service === 'TIKTOK'"
          :sns="sns"
        />
      </BCol>
    </BRow>
    <BRow v-else>
      <BCol class="text-muted font-italic d-flex justify-content-center">
        Empty list
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import SnsFilter from './SnsFilter.vue'
import InstaCard from './InstaCard.vue'
import NaverCard from './NaverCard.vue'
import state from '../../state'
import getters from '../../getters'
import YoutubeCard from './YoutubeCard.vue'
import TiktokCard from './TiktokCard.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    SnsFilter,
    InstaCard,
    NaverCard,
    YoutubeCard,
    TiktokCard,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
