<template>
  <TagbyPrimaryModal
    :visible="state.snsAuthHandle.isVisibleSetSnsAuthStateModal"
    title="SNS로그인정보 상태 수정"
    :content="`SNS로그인정보 상태를 ${state.snsAuthHandle.stateToChange}로 수정하시겠습니까?`"
    @ok="setSnsAuthState"
    @hidden="turnOffSetSnsAuthStateModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useSnsAuth from '../../useSnsAuth'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setSnsAuthState,
      turnOffSetSnsAuthStateModal,
    } = useSnsAuth()

    return {
      state,
      setSnsAuthState,
      turnOffSetSnsAuthStateModal,
    }
  },
})
</script>
