<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isSaving="state.myshopHandle.isSavingMyshop"
    :isValid="state.myshop.idx != null"
    variant="outline-primary"
    text="마이스토어 저장"
    @click="updateMyshop"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useMyshop from '../../useMyshop'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      updateMyshop,
    } = useMyshop()

    return {
      state,
      getters,
      updateMyshop,
    }
  },
})
</script>
