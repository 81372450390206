<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.addressList"
      show-empty
      small
      caption="ADDRESS LIST"
      caption-top
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            :disabled="data.item.state === 'MAIN'"
            text="Set Main"
            @click="turnOnSetAddressStateModal(data.item, 'MAIN')"
          />
          <TagbyTableActionItem
            :disabled="['ACTIVE', 'MAIN'].includes(data.item.state)"
            text="Set Active"
            @click="turnOnSetAddressStateModal(data.item, 'ACTIVE')"
          />
          <TagbyTableActionItem
            :disabled="data.item.state === 'INACTIVE'"
            text="Set Inactive"
            @click="turnOnSetAddressStateModal(data.item, 'INACTIVE')"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import useAddress from '../../useAddress'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const fields = [
      'idx',
      'destination_name',
      'name',
      'phone',
      'address',
      'address_detail',
      'zipcode',
      'state',
      'created_at',
      'actions',
    ]
    const {
      turnOnSetAddressStateModal,
    } = useAddress()

    return {
      state,
      fields,
      turnOnSetAddressStateModal,
    }
  },
})
</script>
