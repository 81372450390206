<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <BFormInput
            v-model="state.myshop.idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="NAME">
          <TagbyCopyableInput
            v-model="state.myshop.name"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="PATH">
          <TagbyCopyableInput
            v-model="state.myshop.urlPath"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow class="mb-1">
      <BCol>
        <BLink
          :href="parseMypageUrl(state.myshop.urlPath)"
          target="_blank"
        >
          {{ parseMypageUrl(state.myshop.urlPath) }}
        </BLink>
      </BCol>
    </BRow>
    <BRow class="mb-1">
      <BCol cols="3">
        product count
      </BCol>
      <BCol cols="3">
        {{ state.myshop.productCount }}
      </BCol>
      <BCol cols="3">
        order amount
      </BCol>
      <BCol cols="3">
        {{ state.myshop.orderAmount }}
      </BCol>
    </BRow>
    <BRow class="mb-1">
      <BCol cols="3">
        delivery count
      </BCol>
      <BCol cols="3">
        {{ state.myshop.deliveryCount }}
      </BCol>
      <BCol cols="3">
        order count
      </BCol>
      <BCol cols="3">
        {{ state.myshop.orderCount }}
      </BCol>
    </BRow>
    <BRow class="mb-1">
      <BCol
        class="blockquote-footer"
      >
        in 30 days
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="INTRO TEXT">
          <BFormTextarea v-model="state.myshop.introText" />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="INSTA">
          <TagbyCopyableInput v-model="state.myshop.insta" />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="NAVER BLOG">
          <TagbyCopyableInput v-model="state.myshop.naverBlog" />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="YOUTUBE">
          <TagbyCopyableInput v-model="state.myshop.youtube" />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAvatar,
  BFormTextarea,
  BLink,
} from 'bootstrap-vue'
import state from '../../state'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BLink,
    TagbyCopyableInput,
  },
  setup() {
    const parseMypageUrl = path => `${process.env.VUE_APP_MYSTORE_URL}/${path}`

    return {
      state,
      parseMypageUrl,
    }
  },
})
</script>
