<template>
  <BRow>
    <BCol>
      <BFormCheckbox
        v-model="state.snsFilter.excludeInactive"
        switch
      >
        INACTIVE 제외
      </BFormCheckbox>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import state from '../../state'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
