<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="PROGRESSING CLAIM">
          <BFormInput
            v-model="getters.claimCount"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="CAUTION">
          <BFormInput
            v-model="state.cautionCount"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="SCORE">
          <BFormInput
            v-model="state.score"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="POINT">
          <TagbyMoneyInput
            v-model="state.point"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="EXPECTED POINT">
          <TagbyMoneyInput
            v-model="state.expectedPoint"
            disabled
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyMoneyInput,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
