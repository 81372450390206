<template>
  <BCard>
    <BTable
      :fields="fields"
      :items="state.ssnList"
      show-empty
      small
    >
      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            :disabled="data.item.state === 'ACTIVE'"
            text="Set Active"
            @click="turnOnSetSsnStateModal(data.item, 'ACTIVE')"
          />
          <TagbyTableActionItem
            :disabled="data.item.state === 'INACTIVE'"
            text="Set Inactive"
            @click="turnOnSetSsnStateModal(data.item, 'INACTIVE')"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BRow,
  BCol,
} from 'bootstrap-vue'
import state from '../../state'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import useSsn from '../../useSsn'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    TagbyDatetimeColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const fields = [
      'idx',
      'name',
      'ssn',
      'state',
      'created_at',
      'actions',
    ]
    const {
      turnOnSetSsnStateModal,
    } = useSsn()
    return {
      state,
      fields,
      turnOnSetSsnStateModal,
    }
  },
})
</script>
