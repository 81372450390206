import state from './state'
import getters from './getters'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'
import stateOrigin from './stateOrigin'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const resetManagePointSidebar = () => {
    const origin = stateOrigin()
    state.managePointSidebar = origin.managePointSidebar
  }

  const turnOnManagePointSidebar = () => {
    resetManagePointSidebar()
    state.managePointSidebar.isVisible = true
  }
  const turnOffManagePointSidebar = () => {
    state.managePointSidebar.isVisible = false
  }

  const getParams = () => {
    const params = {}
    params.inf_idx = state.idx

    params.apply_point = getters.managePointSidebar.applyPoint
    params.tax = getters.managePointSidebar.pointTax
    params.reason = state.managePointSidebar.reason
    params.state = state.managePointSidebar.state

    if (params.state === 'ADDITION') {
      params.expire_at = state.managePointSidebar.expiresAt
    } else if (params.state === 'ADDITION_EXPECTED') {
      params.addition_expected_date = state.managePointSidebar.additionExpectedDate
    }

    return params
  }

  const managePoint = () => {
    state.managePointSidebar.isSaving = true
    const params = getParams()
    return api.managePoint(params).then(() => {
      makeToast('primary', 'Successfully update point')
      getDetail()
      getTimelineList()
      turnOffManagePointSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to update point')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.managePointSidebar.isSaving = false
    })
  }

  return {
    turnOnManagePointSidebar,
    turnOffManagePointSidebar,
    managePoint,
  }
}
