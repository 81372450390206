<template>
  <BCard>

    <BTable
      :fields="fields"
      :items="state.snsAuthList"
      show-empty
      small
      responsive
      caption="SNS AUTH LIST"
      caption-top
    >
      <template #cell(sns_channel)="data">
        <BBadge :variant="getChannelVariant(data.value)">
          {{ data.value }}
        </BBadge>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            :disabled="data.item.state === 'ACTIVE'"
            text="Set Active"
            @click="turnOnSetSnsAuthStateModal(data.item, 'ACTIVE')"
          />
          <TagbyTableActionItem
            :disabled="data.item.state === 'INACTIVE'"
            text="Set Inactive"
            @click="turnOnSetSnsAuthStateModal(data.item, 'INACTIVE')"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import state from '../../state'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import useSnsAuth from '../../useSnsAuth'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BBadge,
    BFormCheckbox,
    TagbyCopyIcon,
    TagbyDatetimeColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const fields = [
      'idx',
      'sns_channel',
      'sns_uid',
      'state',
      'created_at',
      'actions',
    ]
    const getChannelVariant = channel => {
      if (channel === 'NAVER') {
        return 'success'
      }
      if (channel === 'KAKAO') {
        return 'warning'
      }
      if (channel === 'FACEBOOK') {
        return 'primary'
      }
      if (channel === 'GOOGLE') {
        return 'secondary'
      }
      if (channel === 'APPLE') {
        return 'dark'
      }
    }

    const {
      turnOnSetSnsAuthStateModal,
    } = useSnsAuth()

    return {
      state,
      fields,
      getChannelVariant,
      turnOnSetSnsAuthStateModal,
    }
  },
})
</script>
