<template>
  <TagbySidebarWithButton
    v-if="state.myshopHandle.targetMyshop != null"
    :visible="state.myshopHandle.isVisibleUpdateMyshopSidebar"
    :isSaving="state.myshopHandle.isSavingUpdateMyshop"
    :isValid="true"
    title="마이스토어 수정"
    width="30%"
    @ok="miniumUpdateMyshop"
    @hidden="turnOffUpdateMyshopSidebar"
    @cancel="turnOffUpdateMyshopSidebar"
  >
    <TagbyInputRow label="NAME">
      <BFormInput v-model="state.myshopHandle.targetMyshop.name" />
    </TagbyInputRow>

    <TagbyInputRow label="URL PATH">
      <BFormInput v-model="state.myshopHandle.targetMyshop.url_path" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useMyshop from '../../useMyshop'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
  },
  setup() {
    const {
      turnOffUpdateMyshopSidebar,
      miniumUpdateMyshop,
    } = useMyshop()

    return {
      state,
      turnOffUpdateMyshopSidebar,
      miniumUpdateMyshop,
    }
  },
})
</script>
