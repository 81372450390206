<template>
  <TagbyActionButton
    v-if="!getters.isCreatingMode"
    :isValid="true"
    variant="outline-secondary"
    text="주소지 추가"
    @click="turnOnAddAddressSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import state from '../../state'
import getters from '../../getters'
import useAddress from '../../useAddress'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    const {
      turnOnAddAddressSidebar,
    } = useAddress()

    return {
      state,
      getters,
      turnOnAddAddressSidebar,
    }
  },
})
</script>
