export default () => ({
  hasLoadError: false,
  isVisibleErrorReasonModal: false,
  adminPermission: null,
  idx: null,
  name: null,
  phone: null,
  gender: "F",
  job: null,
  birth: null,
  state: "ACTIVE",
  restrictedDate: null,
  point: null,
  expectedPoint: null,
  cautionCount: null,
  scoreList: [],
  scoreLogList: [],
  campaignList: [],
  addressList: [],
  ssnList: [],
  account: null,
  cardList: [],
  agreeList: [],
  agreeLogList: [],
  myshopList: [],
  myshop: {
    idx: null,
    name: null,
    urlPath: null,
    introText: null,
    insta: null,
    naverBlog: null,
    youtube: null,
    productCount: 0,
    orderAmount: 0,
    deliveryCount: 0,
    orderCount: 0,
  },
  tagbyLink: {
    idx: null,
    urlPath: null,
  },
  snsAuthList: [],
  snsList: [],
  deviceList: [],
  token: null,
  cautionList: [],
  claimList: [],
  isBlind: true,
  lastLoginAt: null,
  createdAt: null,
  campaignFilter: {
    applyState: [],
    isProgressing: false,
    in30days: false,
    title: null,
    perPage: 5,
    currentPage: 1,
  },
  snsFilter: {
    excludeInactive: true,
  },
  snapshot: null,
  snsHandle: {
    targetSns: null,
    stateToChange: null,
    isVisibleSetSnsStateModal: false,
    isVisibleIssueTokenModal: false,
    linkUrl: null,
  },
  timelineList: [],
  myshopHandle: {
    targetMyshop: null,
    stateToChange: null,
    isVisibleSetMyshopStateModal: false,
    isVisibleUpdateMyshopSidebar: false,
    isSavingUpdateMyshop: false,
    isSavingMyshop: false,
    snapshot: null,
  },
  snsAuthHandle: {
    targetSnsAuth: null,
    stateToChange: null,
    isVisibleSetSnsAuthStateModal: false,
  },
  ssnHandle: {
    targetSsn: null,
    stateToChange: null,
    isVisibleSetSsnStateModal: false,
  },
  cautionHandle: {
    targetCaution: null,
    stateToChange: null,
    isVisibleSetCautionStateModal: false,
  },
  addressHandle: {
    targetAddress: null,
    stateToChange: null,
    isVisibleSetAddressStateModal: false,
    isVisibleAddAddressSidebar: false,
    name: null,
    phone: null,
    address: null,
    addressDetail: null,
    zipcode: null,
    destinationName: null,
    isSavingAddAddress: false,
  },
  infHandle: {
    isSavingInf: false,
  },
  managePointSidebar: {
    isVisible: false,
    isSaving: false,
    amount: null,
    taxPercent: 0,
    state: "ADDITION",
    reason: null,
    expiresAt: null,
    additionExpectedDate: null,
  },
  addCautionSidebar: {
    isVisible: false,
    isSaving: false,
    infoTable: "campaign_info",
    infoIdx: null,
    selectedReason: null,
    writtenReason: null,
  },
  memoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
  updateScoreSidebar: {
    isVisible: false,
    isSaving: false,
    timeScore: null,
    guideScore: null,
    contactScore: null,
    comment: null,
  },
  banInfModal: {
    isVisible: false,
  },
  withdrawInfSidebar: {
    isVisible: false,
    isSaving: false,
    selectedReason: null,
    writtenReason: null,
  },
  addSnsSidebar: {
    isVisible: false,
    isSaving: false,
    service: "INSTA_BASIC",
    username: null,
  },
  bank: {
    idx: null,
    name: null,
    number: null,
    holder: null,
  },
})
