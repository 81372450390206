import state from './state'
import getters from './getters'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getMyshop,
    getMyshopPipeline,
    getTimelineList,
  } = useFetch()

  const turnOnSetMyshopStateModal = (myshop, stateToChange) => {
    state.myshopHandle.targetMyshop = myshop
    state.myshopHandle.stateToChange = stateToChange
    state.myshopHandle.isVisibleSetMyshopStateModal = true
  }
  const turnOffSetMyshopStateModal = () => {
    state.myshopHandle.isVisibleSetMyshopStateModal = false
  }

  const setMyshopState = () => api.setMyshopState({
    inf_idx: state.idx,
    myshop_idx: state.myshopHandle.targetMyshop.idx,
    state: state.myshopHandle.stateToChange,
  }).then(() => {
    makeToast('primary', 'Successfully changed myshop state')
    getTimelineList()
    getMyshopPipeline()
  }).catch(err => {
    makeToast('danger', 'Failed to change myshop state')
    turnOnErrorReasonModal(err)
  })

  const turnOnUpdateMyshopSidebar = myshop => {
    state.myshopHandle.targetMyshop = {
      ...myshop,
    }
    state.myshopHandle.isVisibleUpdateMyshopSidebar = true
  }
  const turnOffUpdateMyshopSidebar = () => {
    state.myshopHandle.isVisibleUpdateMyshopSidebar = false
  }

  const miniumUpdateMyshop = () => {
    state.myshopHandle.isSavingUpdateMyshop = true
    return api.updateMyshop({
      inf_idx: state.idx,
      myshop_idx: state.myshopHandle.targetMyshop.idx,
      name: state.myshopHandle.targetMyshop.name,
      url_path: state.myshopHandle.targetMyshop.url_path,
    }).then(() => {
      makeToast('primary', 'Successfully updated myshop')
      getMyshopPipeline()
      turnOffUpdateMyshopSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to change myshop')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.myshopHandle.isSavingUpdateMyshop = false
    })
  }

  const parseSnsListJson = () => ({
    INSTA: state.myshop.insta,
    NAVER_BLOG: state.myshop.naverBlog,
    YOUTUBE: state.myshop.youtube,
  })

  const getParams = () => {
    const params = {}
    params.inf_idx = state.idx
    params.myshop_idx = state.myshop.idx
    if (state.myshopHandle.snapshot.name !== state.myshop.name) {
      params.name = state.myshop.name
    }

    if (state.myshopHandle.snapshot.url_path !== state.myshop.urlPath) {
      params.url_path = state.myshop.urlPath
    }

    if (state.myshopHandle.snapshot.intro_text !== state.myshop.introText) {
      params.intro_text = state.myshop.introText
    }

    const snsListJson = parseSnsListJson()
    if (JSON.stringify(state.myshopHandle.snapshot.sns_list_json) !== JSON.stringify(snsListJson)) {
      params.sns_list_json = snsListJson
    }

    return params
  }

  const updateMyshop = () => {
    state.myshopHandle.isSavingMyshop = true
    const params = getParams()
    return api.updateMyshop(params).then(() => {
      makeToast('primary', 'Successfully updated myshop info')
      getMyshop()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', 'Failed to update myshop info')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.myshopHandle.isSavingMyshop = false
    })
  }

  return {
    turnOnSetMyshopStateModal,
    turnOffSetMyshopStateModal,
    setMyshopState,

    turnOnUpdateMyshopSidebar,
    turnOffUpdateMyshopSidebar,
    miniumUpdateMyshop,
    updateMyshop,
  }
}
