import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getSsnList,
    getTimelineList,
  } = useFetch()

  const turnOnSetSsnStateModal = (ssn, stateToChange) => {
    state.ssnHandle.targetSsn = ssn
    state.ssnHandle.stateToChange = stateToChange
    state.ssnHandle.isVisibleSetSsnStateModal = true
  }
  const turnOffSetSsnStateModal = () => {
    state.ssnHandle.isVisibleSetSsnStateModal = false
  }

  const setSsnState = () => api.setSsnState({
    inf_idx: state.idx,
    ssn_idx: state.ssnHandle.targetSsn.idx,
    state: state.ssnHandle.stateToChange,
  }).then(() => {
    makeToast('primary', 'Successfully changed ssn state')
    getSsnList()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', 'Failed to change ssn state')
    turnOnErrorReasonModal(err)
  })

  return {
    turnOnSetSsnStateModal,
    turnOffSetSsnStateModal,
    setSsnState,
  }
}
