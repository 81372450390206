<template>
  <TagbyPrimaryModal
    :visible="state.cautionHandle.isVisibleSetCautionStateModal"
    title="경고상태 수정"
    @ok="setCautionState"
    @hidden="turnOffSetCautionStateModal"
  >
    <div>
      경고상태를 {{ state.cautionHandle.stateToChange }}로 수정하시겠습니까?
    </div>
    <div class="text-danger">
      인플루언서에게 알림이 전송됩니다.
    </div>
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useCaution from '../../useCaution'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setCautionState,
      turnOffSetCautionStateModal,
    } = useCaution()

    return {
      state,
      setCautionState,
      turnOffSetCautionStateModal,
    }
  },
})
</script>
