import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.memoSidebar.text = null
    state.memoSidebar.isVisible = true
  }
  const turnOffSidebar = () => {
    state.memoSidebar.isVisible = false
  }

  const createMemo = () => {
    state.memoSidebar.isSaving = true
    return api.createMemo({
      info_table: 'influencer_info',
      info_idx: state.idx,
      text: state.memoSidebar.text,
    }).then(() => {
      makeToast('primary', 'Successfully created memo')
      getTimelineList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to create memo')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.memoSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    createMemo,
  }
}
