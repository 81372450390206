<template>
  <TagbySidebarWithButton
    :visible="state.addressHandle.isVisibleAddAddressSidebar"
    :isSaving="state.addressHandle.isSavingAddAddress"
    :isValid="true"
    title="주소 추가"
    width="30%"
    @ok="addAddress"
    @hidden="turnOffAddAddressSidebar"
    @cancel="turnOffAddAddressSidebar"
  >
    <TagbyInputRow label="DESTINATION NAME">
      <BFormInput v-model="state.addressHandle.destinationName" />
    </TagbyInputRow>

    <TagbyInputRow label="NAME">
      <BFormInput v-model="state.addressHandle.name" />
    </TagbyInputRow>

    <TagbyInputRow label="PHONE">
      <BFormInput v-model="state.addressHandle.phone" />
    </TagbyInputRow>

    <TagbyInputRow label="ADDRESS">
      <BFormInput v-model="state.addressHandle.address" />
    </TagbyInputRow>

    <TagbyInputRow label="ADDRESS DETAIL">
      <BFormInput v-model="state.addressHandle.addressDetail" />
    </TagbyInputRow>

    <TagbyInputRow label="ZIPCODE">
      <BFormInput v-model="state.addressHandle.zipcode" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useAddress from '../../useAddress'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
  },
  setup() {
    const {
      turnOffAddAddressSidebar,
      addAddress,
    } = useAddress()

    return {
      state,
      turnOffAddAddressSidebar,
      addAddress,
    }
  },
})
</script>
