import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'
import stateOrigin from './stateOrigin'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getDetail,
    getCautionList,
    getTimelineList,
  } = useFetch()

  const turnOnSetCautionStateModal = (caution, stateToChange) => {
    state.cautionHandle.targetCaution = caution
    state.cautionHandle.stateToChange = stateToChange
    state.cautionHandle.isVisibleSetCautionStateModal = true
  }
  const turnOffSetCautionStateModal = () => {
    state.cautionHandle.isVisibleSetCautionStateModal = false
  }

  const setCautionState = () => api.setCautionState({
    inf_idx: state.idx,
    caution_idx: state.cautionHandle.targetCaution.idx,
    state: state.cautionHandle.stateToChange,
  }).then(() => {
    makeToast('primary', 'Successfully changed caution state')
    getDetail()
    getCautionList()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', 'Failed to change caution state')
    turnOnErrorReasonModal(err)
  })

  const resetAddCautionSidebar = () => {
    const origin = stateOrigin()
    state.addCautionSidebar = origin.addCautionSidebar
  }
  const turnOnAddCautionSidebar = () => {
    resetAddCautionSidebar()
    state.addCautionSidebar.isVisible = true
  }
  const turnOffAddCautionSidebar = () => {
    state.addCautionSidebar.isVisible = false
  }
  const getCautionReason = () => {
    if (state.addCautionSidebar.infoTable === 'etc') {
      return state.addCautionSidebar.writtenReason
    }
    if (state.addCautionSidebar.selectedReason !== '기타 (직접입력)') {
      return state.addCautionSidebar.selectedReason
    }
    return state.addCautionSidebar.writtenReason
  }
  const addCaution = () => {
    state.addCautionSidebar.isSaving = true
    return api.addCaution({
      inf_idx: state.idx,
      info_table: state.addCautionSidebar.infoTable,
      info_idx: state.addCautionSidebar.infoIdx,
      reason: getCautionReason(),
    }).then(() => {
      makeToast('primary', 'Successfully added caution')
      getDetail()
      getCautionList()
      getTimelineList()
      turnOffAddCautionSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to add caution')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.addCautionSidebar.isSaving = false
    })
  }

  return {
    turnOnSetCautionStateModal,
    turnOffSetCautionStateModal,
    setCautionState,

    turnOnAddCautionSidebar,
    turnOffAddCautionSidebar,
    addCaution,
  }
}
