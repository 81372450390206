<template>
  <TagbySidebarWithButton
    :visible="state.addSnsSidebar.isVisible"
    :isSaving="state.addSnsSidebar.isSaving"
    :isValid="true"
    title="SNS 추가"
    width="30%"
    @ok="addSns"
    @hidden="turnOffAddSnsSidebar"
    @cancel="turnOffAddSnsSidebar"
  >
    <TagbyInputRow label="SERVICE">
      <TagbySelect
        v-model="state.addSnsSidebar.service"
        :options="serviceOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow label="USERNAME">
      <BFormInput v-model="state.addSnsSidebar.username" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import useSns from '../../useSns'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    TagbySelect,
    BFormInput,
  },
  setup() {
    const {
      turnOffAddSnsSidebar,
      addSns,
    } = useSns()

    const serviceOptions = [
      'INSTA_BASIC',
      'NAVER_BLOG',
      'YOUTUBE',
    ]

    return {
      state,
      serviceOptions,
      turnOffAddSnsSidebar,
      addSns,
    }
  },
})
</script>
