import moment from 'moment'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getSnsList,
    getTimelineList,
  } = useFetch()

  const setSnsState = () => api.setSnsState({
    inf_idx: state.idx,
    sns_idx: state.snsHandle.targetSns.idx,
    state: state.snsHandle.stateToChange,
  }).then(() => {
    makeToast('primary', 'Successfully changed SNS state')
    getSnsList()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', 'Failed to change SNS state')
    turnOnErrorReasonModal(err)
  })

  const turnOnSetSnsStateModal = (sns, stateToChange) => {
    state.snsHandle.targetSns = sns
    state.snsHandle.stateToChange = stateToChange
    state.snsHandle.isVisibleSetSnsStateModal = true
  }
  const turnOffSetSnsStateModal = () => {
    state.snsHandle.isVisibleSetSnsStateModal = false
  }

  const turnOnIssueTicketModal = () => {
    state.snsHandle.isVisibleIssueTokenModal = true
  }
  const turnOffIssueTicketModal = () => {
    state.snsHandle.isVisibleIssueTokenModal = false
  }

  const issueTicket = sns => api.issueTicket({
    inf_idx: state.idx,
    sns_idx: sns.idx,
  }).then(response => {
    makeToast('primary', 'Successfully issued ticket')
    state.snsHandle.linkUrl = response.data.data.link_url
    getTimelineList()
    turnOnIssueTicketModal()
  }).catch(err => {
    makeToast('danger', 'Failed to issue ticket')
    turnOnErrorReasonModal(err)
  })

  const turnOnAddSnsSidebar = () => {
    state.addSnsSidebar.isVisible = true
    state.addSnsSidebar.username = null
  }
  const turnOffAddSnsSidebar = () => {
    state.addSnsSidebar.isVisible = false
  }
  const addSns = () => {
    state.addSnsSidebar.isSaving = true
    return api.addSns({
      inf_idx: state.idx,
      service: state.addSnsSidebar.service,
      username: state.addSnsSidebar.username,
    }).then(() => {
      makeToast('primary', 'Successfully added sns')
      getSnsList()
      getTimelineList()
      turnOffAddSnsSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to add sns')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.addSnsSidebar.isSaving = false
    })
  }

  return {
    setSnsState,
    turnOnSetSnsStateModal,
    turnOffSetSnsStateModal,

    issueTicket,
    turnOnIssueTicketModal,
    turnOffIssueTicketModal,

    turnOnAddSnsSidebar,
    turnOffAddSnsSidebar,
    addSns,
  }
}
