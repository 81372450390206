<template>
  <TagbyPrimaryModal
    :visible="state.myshopHandle.isVisibleSetMyshopStateModal"
    title="마이스토어 상태 수정"
    :content="`마이스토어 상태를 ${state.myshopHandle.stateToChange}로 수정하시겠습니까?`"
    @ok="setMyshopState"
    @hidden="turnOffSetMyshopStateModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useMyshop from '../../useMyshop'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setMyshopState,
      turnOffSetMyshopStateModal,
    } = useMyshop()

    return {
      state,
      setMyshopState,
      turnOffSetMyshopStateModal,
    }
  },
})
</script>
