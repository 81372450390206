<template>
  <BCard v-if="state.tagbyLink.idx != null">
    <BRow>
      <BCol>
        <BFormGroup label="URL PATH">
          <BFormInput v-model="state.tagbyLink.urlPath" />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BLink :href="getParsedUrlPath(state.tagbyLink.urlPath)">
          {{ getParsedUrlPath(state.tagbyLink.urlPath) }}
        </BLink>
      </BCol>
    </BRow>
  </BCard>
  <BRow v-else>
    <BCol class="text-muted font-italic d-flex justify-content-center">
      Empty
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
} from 'bootstrap-vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import state from '../../state'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    TagbyCopyIcon,
  },
  setup() {
    const getParsedUrlPath = path => `${process.env.VUE_APP_TAGBY_LINK_SERVER}/${path}`
    return {
      state,
      getParsedUrlPath,
    }
  },
})
</script>
