<template>
  <TagbyActionCard>
    <template #left>
      <!-- <CreateButton /> -->
      <UpdateInfButton />
      <AddAddressButton />
      <ManagePointButton />
      <AddCautionButton />
      <UpdateScoreButton />
    </template>

    <template #right>
      <UpdateMyshopButton />
      <SetMyshopInactiveButton />
      <MemoButton />
      <WithdrawButton />
      <BanButton />
      <AddSnsButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
// import CreateButton from './CreateButton.vue'
import UpdateInfButton from './UpdateInfButton.vue'
import UpdateMyshopButton from './UpdateMyshopButton.vue'
import AddAddressButton from './AddAddressButton.vue'
import ManagePointButton from './ManagePointButton.vue'
import AddCautionButton from './AddCautionButton.vue'
import MemoButton from './MemoButton.vue'
import WithdrawButton from './WithdrawButton.vue'
import BanButton from './BanButton.vue'
import UpdateScoreButton from './UpdateScoreButton.vue'
import SetMyshopInactiveButton from './SetMyshopInactiveButton.vue'
import AddSnsButton from './AddSnsButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    // CreateButton,
    UpdateInfButton,
    UpdateMyshopButton,
    AddAddressButton,
    ManagePointButton,
    AddCautionButton,
    MemoButton,
    WithdrawButton,
    BanButton,
    UpdateScoreButton,
    SetMyshopInactiveButton,
    AddSnsButton,
  },
  setup() {

  },
})
</script>
