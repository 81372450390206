<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        sm="4"
      >
        은행: {{ state.bank.name }}
      </BCol>
      <BCol
        cols="12"
        sm="4"
      >
        계좌번호: {{ state.bank.number }} <TagbyCopyIcon :value="state.bank.number" />
      </BCol>
      <BCol
        cols="12"
        sm="4"
      >
        예금주: {{ state.bank.holder }}
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue"
import state from "../../state"
import TagbyCopyIcon from "@/components/TagbyCopyIcon.vue"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    TagbyCopyIcon,
  },
  setup() {
    return {
      state,
    }
  },
})
</script>
