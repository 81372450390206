<template>
  <TagbySidebarWithButton
    :visible="state.withdrawInfSidebar.isVisible"
    :isSaving="state.withdrawInfSidebar.isSaving"
    :isValid="true"
    title="인플루언서 탈퇴"
    width="30%"
    @ok="withdrawInf"
    @hidden="turnOffWithdrawInfSidebar"
    @cancel="turnOffWithdrawInfSidebar"
  >
    <TagbyInputRow
      label="REASON"
    >
      <TagbySelect
        v-model="state.withdrawInfSidebar.selectedReason"
        :options="reasonOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-if="state.withdrawInfSidebar.selectedReason === '기타'"
    >
      <BFormTextarea
        v-model="state.withdrawInfSidebar.writtenReason"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import useInf from '../../useInf'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    TagbySelect,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffWithdrawInfSidebar,
      withdrawInf,
    } = useInf()

    const reasonOptions = [
      '쓰지 않는 앱이에요',
      '선정이 잘 안 돼요',
      '포인트 출금이 안 돼요',
      '원하는 캠페인이 없어요',
      '판매하고 싶은 제품이 없어요',
      '제품 판매가 잘 되지 않아요',
      '오류가 생겨서 쓰기 어려워요',
      '앱 사용법을 모르겠어요',
      '기타',
    ]

    return {
      state,
      reasonOptions,
      turnOffWithdrawInfSidebar,
      withdrawInf,
    }
  },
})
</script>
