<template>
  <TagbyPrimaryModal
    :visible="state.snsHandle.isVisibleIssueTokenModal"
    title="웹인증티켓 발행"
    okOnly
    @ok="turnOffIssueTicketModal"
    @hidden="turnOffIssueTicketModal"
  >
    <TagbyCopyIcon :value="state.snsHandle.linkUrl" />
    {{ state.snsHandle.linkUrl }}
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import state from '../../state'
import useSns from '../../useSns'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    TagbyCopyIcon,
  },
  setup() {
    const {
      turnOffIssueTicketModal,
    } = useSns()

    return {
      state,
      turnOffIssueTicketModal,
    }
  },
})
</script>
