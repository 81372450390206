import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getDetail,
    getScoreList,
    getScoreLostList,
    getTimelineList,
  } = useFetch()

  const findScore = type => state.scoreList.find(e => e.type === type)?.score ?? 20

  const turnOnSidebar = () => {
    state.updateScoreSidebar.timeScore = findScore('TIME')
    state.updateScoreSidebar.guideScore = findScore('GUIDE')
    state.updateScoreSidebar.contactScore = findScore('CONTACT')
    state.updateScoreSidebar.comment = null
    state.updateScoreSidebar.isVisible = true
  }
  const turnOffSidebar = () => {
    state.updateScoreSidebar.isVisible = false
  }

  const updateScore = () => {
    state.updateScoreSidebar.isSaving = true
    return api.updateScore({
      inf_idx: state.idx,
      time_score: state.updateScoreSidebar.timeScore,
      guide_score: state.updateScoreSidebar.guideScore,
      contact_score: state.updateScoreSidebar.contactScore,
      comment: state.updateScoreSidebar.comment,
    }).then(() => {
      makeToast('primary', 'Successfully updated score')
      getDetail()
      getScoreList()
      getScoreLostList()
      getTimelineList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to update score')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateScoreSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    updateScore,
  }
}
