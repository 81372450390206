<template>
  <TagbySidebarWithButton
    :visible="state.addCautionSidebar.isVisible"
    :isSaving="state.addCautionSidebar.isSaving"
    :isValid="true"
    title="경고 추가"
    width="30%"
    @ok="addCaution"
    @hidden="turnOffAddCautionSidebar"
    @cancel="turnOffAddCautionSidebar"
  >
    <TagbyInputRow label="TARGET TYPE">
      <TagbySelect
        v-model="state.addCautionSidebar.infoTable"
        :options="targetOptions"
        :reduce="opt => opt.value"
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-if="state.addCautionSidebar.infoTable === 'campaign_info'"
      label="CAMPAIGN IDX"
    >
      <BFormInput
        v-model="state.addCautionSidebar.infoIdx"
        number
        type="number"
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-else-if="state.addCautionSidebar.infoTable === 'product_info'"
      label="PRODUCT IDX"
    >
      <BFormInput
        v-model="state.addCautionSidebar.infoIdx"
        number
        type="number"
      />
    </TagbyInputRow>

    <TagbyInputRow
      label="REASON"
    >
      <TagbySelect
        v-if="state.addCautionSidebar.infoTable === 'campaign_info'"
        v-model="state.addCautionSidebar.selectedReason"
        :options="campaignReasonOptions"
      />
      <TagbySelect
        v-if="state.addCautionSidebar.infoTable === 'product_info'"
        v-model="state.addCautionSidebar.selectedReason"
        :options="marketReasonOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow
      v-if="state.addCautionSidebar.infoTable === 'etc' || state.addCautionSidebar.selectedReason === '기타 (직접입력)'"
    >
      <BFormTextarea
        v-model="state.addCautionSidebar.writtenReason"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import getters from '../../getters'
import useCaution from '../../useCaution'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    TagbySelect,
    TagbyDatetimeInputV3,
  },
  setup() {
    const {
      turnOffAddCautionSidebar,
      addCaution,
    } = useCaution()

    const targetOptions = [
      { label: 'CAMPAIGN', value: 'campaign_info' },
      { label: 'PRODUCT', value: 'product_info' },
      { label: 'ETC', value: 'etc' },
    ]
    const campaignReasonOptions = [
      '미션기간 내에 포스팅을 등록하지 않은 경우',
      '캠페인 가이드를 충분히 이해하지 않고 캠페인을 신청&진행한 경우',
      '포스팅 유지기간 1개월 미준수 및 계정 비공개 전환 등 인증이 불가능 한 경우',
      '가이드에 맞지 않은 포스팅으로 재업로드 및 수정 요청을 수행하지 않은 경우',
      '광고주의 연락을 고의로 받지 않거나, 캠페인 진행에 영향을 미칠 정도로 늦게 연락을 주는 경우',
      '제품 협찬 캠페인의 경우, 제품을 받고 어떠한 연락도 되지 않는 경우',
      '현금 지급 캠페인의 경우 캠페인 기간을 지키지 못할 시에 제공 금액 삭감될 수 있음.',
      '방문 캠페인의 경우, 증빙서류 없는 No-show가 발생할 경우',
      '캠페인 선정 후 일방적으로 진행 취소하는 경우',
      '캠페인 진행으로 제공받은 제공 내역을 타인에게 양도 및 판매, 교환하는 경우',
      '기타 (직접입력)',
    ]
    const marketReasonOptions = [
      '온라인 가격 노출 적발',
      '외부 스토어에 상품 판매 적발',
      '기타 (직접입력)',
    ]

    return {
      state,
      getters,
      targetOptions,
      turnOffAddCautionSidebar,
      addCaution,
      campaignReasonOptions,
      marketReasonOptions,
    }
  },
})
</script>
