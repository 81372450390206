<template>
  <BCard no-body>
    <BRow>
      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup
          label="APPLY STATE"
        >
          <BFormCheckboxGroup
            v-model="state.campaignFilter.applyState"
            :options="applyStateOptions"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="6"
        md="3"
      >
        <BFormGroup
          label="IS PROGRESSING"
        >
          <BFormCheckbox
            v-model="state.campaignFilter.isProgressing"
            switch
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="6"
        md="3"
      >
        <BFormGroup
          label="IN 30 DAYS"
        >
          <BFormCheckbox
            v-model="state.campaignFilter.in30days"
            switch
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup
          label="TITLE"
        >
          <BFormInput
            v-model="state.campaignFilter.title"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
} from 'bootstrap-vue'
import state from '../../state'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
  },
  setup() {
    const applyStateOptions = [
      'APPLY',
      'SELECTED',
      'CANCEL',
      'QUIT',
    ]
    return {
      state,
      applyStateOptions,
    }
  },
})
</script>
