import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getAddressList,
    getTimelineList,
  } = useFetch()

  const turnOnSetAddressStateModal = (address, stateToChange) => {
    state.addressHandle.targetAddress = address
    state.addressHandle.stateToChange = stateToChange
    state.addressHandle.isVisibleSetAddressStateModal = true
  }
  const turnOffSetAddressStateModal = () => {
    state.addressHandle.isVisibleSetAddressStateModal = false
  }

  const getStateChangeFunction = () => {
    if (state.addressHandle.stateToChange === 'ACTIVE') {
      return api.setAddressActive
    }
    if (state.addressHandle.stateToChange === 'INACTIVE') {
      return api.setAddressInactive
    }
    if (state.addressHandle.stateToChange === 'MAIN') {
      return api.setAddressMain
    }
  }

  const setAddressState = () => {
    const func = getStateChangeFunction()
    return func({
      inf_idx: state.idx,
      address_idx: state.addressHandle.targetAddress.idx,
    }).then(() => {
      makeToast('primary', 'Successfully changed address state')
      getAddressList()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', 'Failed to change address state')
      turnOnErrorReasonModal(err)
    })
  }

  const resetAddAddressSidebar = () => {
    state.addressHandle.name = null
    state.addressHandle.phone = null
    state.addressHandle.address = null
    state.addressHandle.addressDetail = null
    state.addressHandle.zipcode = null
    state.addressHandle.destinationName = null
  }

  const turnOnAddAddressSidebar = () => {
    resetAddAddressSidebar()
    state.addressHandle.isVisibleAddAddressSidebar = true
  }
  const turnOffAddAddressSidebar = () => {
    state.addressHandle.isVisibleAddAddressSidebar = false
  }
  const addAddress = () => {
    state.addressHandle.isSavingAddAddress = true
    return api.addAddress({
      inf_idx: state.idx,
      name: state.addressHandle.name,
      phone: state.addressHandle.phone,
      address: state.addressHandle.address,
      address_detail: state.addressHandle.addressDetail,
      zipcode: state.addressHandle.zipcode,
      destination_name: state.addressHandle.destinationName,
    }).then(() => {
      makeToast('primary', 'Successfully added address')
      getAddressList()
      getTimelineList()
      turnOffAddAddressSidebar()
    }).catch(err => {
      makeToast('danger', 'Failed to add address')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.addressHandle.isSavingAddAddress = false
    })
  }

  return {
    turnOnSetAddressStateModal,
    turnOffSetAddressStateModal,
    setAddressState,

    resetAddAddressSidebar,
    turnOnAddAddressSidebar,
    turnOffAddAddressSidebar,
    addAddress,
  }
}
