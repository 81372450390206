<template>
  <BCard title="기본정보">
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <BFormInput
            v-model="state.idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IS BLIND">
          <BFormCheckbox
            v-model="state.isBlind"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="NAME">
          <BFormInput v-model="state.name" />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="PHONE">
          <BFormInput v-model="state.phone" />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="GENDER">
          <TagbySelect
            v-model="state.gender"
            :options="genderOptions"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="JOB">
          <BFormInput v-model="state.job" />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="BIRTH">
          <BFormDatepicker
            v-model="state.birth"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="STATE">
          <TagbySelect
            v-model="state.state"
            :options="stateOptions"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="RESTRICTED">
          <TagbyDatetimeInputV3
            v-model="state.restrictedDate"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
            }"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="LAST LOGIN AT">
          <TagbyDatetimeInputV3
            v-model="state.lastLoginAt"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="CREATED AT">
          <TagbyDatetimeInputV3
            v-model="state.createdAt"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }"
            disabled
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import state from '../../state'
import getters from '../../getters'
import TagbySelect from '@/components/TagbySelect.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    TagbyDatetimeInputV3,
    TagbySelect,
    BFormCheckbox,
  },
  setup() {
    const genderOptions = [
      'F', 'M',
    ]
    const stateOptions = [
      'WAIT',
      'ACTIVE',
      'WITHDRAW',
      'BAN',
      'BAN_WITHDRAW',
      'BAN_WAIT',
      'SLEEP',
      'INACTIVE',
    ]
    return {
      state,
      getters,
      genderOptions,
      stateOptions,
    }
  },
})
</script>
