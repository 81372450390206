import moment from "moment"
import useToast from "@/utils/toast"
import useReloveValue from "@/utils/resolveValue"
import router from "@/router"
import state from "./state"
import getters from "./getters"
import api from "./api"
import useErrorReason from "./useErroReason"

export default () => {
  const {
    makeToast,
  } = useToast()

  // const {
  //   resolveUtcDateTime,
  // } = useReloveValue()

  const {
    turnOnErrorReasonModal,
    turnOffErrorReasonModal,
  } = useErrorReason()

  const getAdminMe = () => api.getAdminMe().then(response => {
    state.adminPermission = response.data.data.permission
  })

  const getDetail = () => api.getDetail({
    idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.name = resData.name
    state.phone = resData.phone
    state.state = resData.state
    state.restrictedDate = resData.restricted_date
    state.gender = resData.gender
    state.birth = resData.birth
    state.job = resData.job
    state.lastLoginAt = resData.last_login_at
    state.createdAt = resData.created_at
    state.point = resData.point
    state.expectedPoint = resData.expected_point
    state.score = resData.score
    state.cautionCount = resData.caution_count ?? 0
    state.isBlind = resData.is_blind
    state.snapshot = resData
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getCampaignList = () => api.getCampaignList({
    inf_idx: state.idx,
  }).then(response => {
    state.campaignList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve campaign list")
    turnOnErrorReasonModal(err)
  })

  const getSnsList = () => api.getSnsList({
    inf_idx: state.idx,
  }).then(response => {
    state.snsList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve sns list")
    turnOnErrorReasonModal(err)
  })

  const getMyshopList = () => api.getMyshopList({
    inf_idx: state.idx,
  }).then(response => {
    state.myshopList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve myshop list")
    turnOnErrorReasonModal(err)
  })

  const getMyshop = () => api.getMyshop({
    myshop_idx: getters.activeMyshopIdx,
  }).then(response => {
    const resData = response.data.data
    state.myshop.idx = resData.idx
    state.myshop.name = resData.name
    state.myshop.urlPath = resData.url_path
    state.myshop.introText = resData.intro_text
    state.myshop.state = resData.state
    state.myshop.productCount = resData.product_count
    state.myshop.orderCount = resData.order_count
    state.myshop.deliveryCount = resData.deliver_count
    state.myshop.orderAmount = resData.order_amount
    state.myshop.insta = resData.sns_list_json.INSTA
    state.myshop.naverBlog = resData.sns_list_json.NAVER_BLOG
    state.myshop.youtube = resData.sns_list_json.YOUTUBE
    state.myshopHandle.snapshot = resData
  }).catch(err => {
    makeToast("danger", "Failed to retrieve myshop")
    turnOnErrorReasonModal(err)
  })

  const getInfToken = () => api.getInfToken({
    inf_idx: state.idx,
  }).then(response => {
    state.token = response.data.data?.token
  }).catch(err => {
    makeToast("danger", "Failed to retrieve token info")
    turnOnErrorReasonModal(err)
  })

  const getDeviceList = () => api.getDeviceList({
    inf_idx: state.idx,
  }).then(response => {
    state.deviceList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve device list")
    turnOnErrorReasonModal(err)
  })

  const getSnsAuthList = () => api.getSnsAuthList({
    inf_idx: state.idx,
  }).then(response => {
    state.snsAuthList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve sns auth list")
    turnOnErrorReasonModal(err)
  })

  const getTagbyLink = () => api.getTagbyLink({
    inf_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.tagbyLink.idx = resData?.idx
    state.tagbyLink.urlPath = resData?.url_path
  }).catch(err => {
    makeToast("danger", "Failed to retrieve tagby link")
    turnOnErrorReasonModal(err)
  })

  const getSsnList = () => api.getSsnList({
    inf_idx: state.idx,
  }).then(response => {
    state.ssnList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve ssn list")
    turnOnErrorReasonModal(err)
  })

  const getAgreeList = () => api.getAgreeList({
    inf_idx: state.idx,
  }).then(response => {
    state.agreeList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve agree list")
    turnOnErrorReasonModal(err)
  })

  const getAgreeLogList = () => api.getAgreeLogList({
    inf_idx: state.idx,
  }).then(response => {
    state.agreeLogList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve agree log list")
    turnOnErrorReasonModal(err)
  })

  const getClaimList = () => api.getClaimList({
    inf_idx: state.idx,
  }).then(response => {
    state.claimList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve claim list")
    turnOnErrorReasonModal(err)
  })

  const getCautionList = () => api.getCautionList({
    inf_idx: state.idx,
  }).then(response => {
    state.cautionList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve caution list")
    turnOnErrorReasonModal(err)
  })

  const getScoreList = () => api.getScoreList({
    inf_idx: state.idx,
  }).then(response => {
    state.scoreList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve score list")
    turnOnErrorReasonModal(err)
  })

  const getScoreLostList = () => api.getScoreLostList({
    inf_idx: state.idx,
  }).then(response => {
    state.scoreLogList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve score log list")
    turnOnErrorReasonModal(err)
  })

  const getAddressList = () => api.getAddressList({
    inf_idx: state.idx,
  }).then(response => {
    state.addressList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve address list")
    turnOnErrorReasonModal(err)
  })

  const getTimelineList = () => api.getTimelineList({
    inf_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    makeToast("danger", "Failed to retrieve timeline list")
    turnOnErrorReasonModal(err)
  })

  const getMyshopPipeline = () => getMyshopList().then(() => {
    if (getters.activeMyshopIdx != null) {
      getMyshop()
    }
  })

  const getBank = () => api.getBank({
    inf_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.bank.idx = resData.idx
    state.bank.name = resData.bank_name
    state.bank.number = resData.bank_number
    state.bank.holder = resData.bank_holder
    console.log(state.bank)
  }).catch(err => {
    makeToast("danger", "은행 정보를 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  return {
    getAdminMe,
    getDetail,
    turnOffErrorReasonModal,
    getCampaignList,
    getSnsList,
    getMyshopList,
    getMyshop,
    getInfToken,
    getDeviceList,
    getSnsAuthList,
    getTagbyLink,
    getSsnList,
    getAgreeList,
    getAgreeLogList,
    getClaimList,
    getCautionList,
    getScoreList,
    getScoreLostList,
    getAddressList,
    getTimelineList,
    getMyshopPipeline,
    getBank,
  }
}
